import { useSubscription } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useToast } from '~/src/components/generic/Toast/hook/useToast'

import { useNotification } from '~/src/components/shared/Notification/hooks/useNotification'
import { SUBSCRIBE_NOTIFICATIONS } from '~/src/components/shared/Notification/queries'
import {
  SubscribeUserNotificationsSubscription,
  SubscribeUserNotificationsSubscriptionVariables,
} from '~/src/components/shared/Notification/__generated_types__/queries.gql'
import { NotificationMessage } from '~/src/components/shared/Notification/components/NotificationMessage'

import { useAuthenticatedUser } from '~/hooks/useAuthentication'

import { ERROR_DELAY } from '~/src/components/Operational/LoadBoardDashboard/constants'

import { NotificationType, RiskTier } from '~/__generated_types__/globalTypes'

const useSubscribeNotifications = () => {
  const notificationContext = useNotification()
  const toast = useToast()
  const [user] = useAuthenticatedUser()
  const { showNotifications, showLatenessTabAndNotifications } = useFlags()

  useSubscription<
    SubscribeUserNotificationsSubscription,
    SubscribeUserNotificationsSubscriptionVariables
  >(SUBSCRIBE_NOTIFICATIONS, {
    skip: !showNotifications,
    variables: {
      userId: user.id,
    },
    onData: ({ data }) => {
      if (data.data?.subscribeUserNotifications) {
        const existingNotifications = notificationContext?.notifications
        const newNotifications = data.data.subscribeUserNotifications.filter(
          (newNotification) =>
            !existingNotifications?.some(
              (notification) => notification.id === newNotification.id
            )
        )
        notificationContext?.update(data.data.subscribeUserNotifications)
        if (notificationContext?.isDropdownOpen) return

        newNotifications.forEach((newNotification) => {
          if (
            !showLatenessTabAndNotifications &&
            newNotification.type === NotificationType.LateAppointment
          )
            return

          const toastMessage = (
            <NotificationMessage notification={newNotification} displayType="toast" />
          )
          const toastOptions = {
            id: newNotification.id,
            delay: ERROR_DELAY,
            style: {
              container: 'bg-white gap-4 text-inherit border border-grey-75',
              content: 'flex items-center justify-center pl-2',
            },
            showCloseButton: false,
          }

          if (newNotification.tier === RiskTier.Critical) {
            toast.error(toastMessage, toastOptions)
          } else if (newNotification.tier === RiskTier.Risk) {
            toast.warning(toastMessage, toastOptions)
          } else {
            toast.success(toastMessage, toastOptions)
          }
        })
      }
    },
  })
}

export default useSubscribeNotifications
